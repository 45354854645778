.component-barcode-scanner{
  width:96%;
  height:96%;
  background:$nav-background;
  position:absolute;
  resize:both;
  top:2%;
  left:2%;
  border-radius: 2vw;
}
.close{
  position:absolute;
  z-index:10000;
  cursor: pointer;
  font-weight: bold;
  color: $orange-text-color;
  right: -5px;
  top: -5px;
  background: $menu-background;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 20px;
  line-height: 120%;
}
.app[data-brand="ka"] {
  .close{
    color: $red-text-color;
  }
}
.dbrScanner-bg-loading{animation:1s linear infinite dbrScanner-rotate;width:40%;height:40%;position:absolute;margin:auto;left:0;top:0;right:0;bottom:0;fill:#aaa;}
.dbrScanner-bg-camera{width:40%;height:40%;position:absolute;margin:auto;left:0;top:0;right:0;bottom:0;fill:#aaa;}
.dbrScanner-video{width:100%;height:100%;position:absolute;left:0;top:0;}
.dbrScanner-cvs-drawarea{width:100%;height:100%;position:absolute;left:0;top:0;}
.dbrScanner-cvs-scanarea{width:100%;height:100%;position:absolute;left:0;top:0;}
.dbrScanner-scanlight{width:100%;height:3%;position:absolute;animation:3s infinite dbrScanner-scanlight;border-radius:50%;box-shadow:0px 0px 2vw 1px #00e5ff;background:#fff;}
.dbrScanner-sel-camera{margin:0 auto;position:absolute;left:0;top:0;}
.dbrScanner-sel-resolution{position:absolute;left:0;top:20px;}
@keyframes dbrScanner-rotate{from{transform:rotate(0turn);}to{transform:rotate(1turn);}}
@keyframes dbrScanner-scanlight{from{top:0;}to{top:97%;}}