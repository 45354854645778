@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  @return calc($pxval / $base-font-size) * 1rem;
}


@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 1440px) {
    @content;
  }
}

@function fontSize($var1, $var2, $var3, $var4, $var5 ) {
  @return ($var1 + ($var2 - $var1)* (100vw - $var3)/($var4 - $var5));
}

//@mixin font($w, $s, $lh: 1.2) {
//  font-family: map-get(map-get($fonts, $w), 'fontFamily');
//  font-weight: map-get(map-get($fonts, $w), 'fontWeight');
//  line-height: $lh;
//}

