.m-page-loader {
  position: absolute;
  right: rem(35);
  top: rem(0);
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  z-index: 99999;
  display: block;
  &__loader {
    width: rem(7);
    height: rem(7);
    left: 50%;
    top: 50%;
    border-radius: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &__loader:before, &__loader:after {
    content: "";
    position: absolute;
    top: rem(-5);
    left: rem(-5);
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: rem(5) solid transparent;
    border-top-color: $orange-text-color;
  }
  &__loader:before {
    z-index: 100;
    animation: spin 1.2s infinite;
  }
  &__loader:after {
    border: rem(5) solid #f9f9f9;
  }
  &--light{
    background: transparent;
    .m-page-loader__loader {
      width: rem(15);
      height: rem(15);
      left: 135%;
      top: 50%;
      border-radius: 100%;
      position: relative;
      transform: translate(-50%, -50%);
    }
  }
}

.app[data-brand="ka"] {
  .m-page-loader {
    &__loader:before, &__loader:after {
      border-top-color: $red-text-color;
    }
  }
}
// Keyframes variables
$left: calc(-330px - 50%);
$right: calc(330px - 50%);
// Keyframes
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



@include desktop {
  .m-page-loader {
    &--light{
      .m-page-loader__loader {
        width: rem(25);
        height: rem(25);
        left: 50%;
        top: 50%;
      }
    }
  }
}