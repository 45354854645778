html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: $menu-background;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}

.app {
  font-family: 'Montserrat', sans-serif;
  /*font-family: 'Titillium Web', sans-serif;*/
  /*font-family: 'Nunito Sans', sans-serif;*/
  background-image: url("../images/gp_background.jpg");
  background-repeat:no-repeat;
  background-position: center;
  background-size: cover;

  .container {
    padding: 0.5vw 1vw;
  }


  .popup-cta {
    cursor: pointer;
    text-decoration: underline;
    font-size: 3.5vw;
    text-align: center;
  }


  .btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 3vw;

    button {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      background-color: $orange-text-color;
      color: white;
      font-weight: lighter;
      border: 0 solid white;
      height: 8.5vw;
      width: 30vw;
      font-size: 3.1vw;
      // box-shadow: 0 0 12px rgb(238 112 3 / 25%);
    }


    &.active {
      button {
        cursor: pointer;
      }

      &:hover {
        button {
          background-color: $orange-btn-hover;
        }
      }
    }

    &.secondary {
      button {
        cursor: pointer;
        border: rem(1) solid $orange-text-color;
        background-color: white;
        color: $orange-text-color;
      }

      &:hover {
        button {
          background-color: $orange-btn-hover;
          color: white;
        }
      }
    }
  }

  @include desktop {
    .popup-cta {
      font-size: 1.2vw;
      width: 90%;
      text-align: left;

      &.tc {
        font-size: 1vw;
        line-height: rem(48);
      }
    }
    .btn {
      width: 20%;
      padding-bottom: 0;

      button {
        height: 3.7vw;
        width: 100%;
        font-size: 1vw;
      }
    }
  }

}

.app[data-brand="ka"] {

  .btn {

    button {
      background-color: $red-text-color;
    }

    &.active {

      &:hover {
        button {
          background-color: $red-btn-hover;
        }
      }
    }

    &.secondary {
      button {
       
        border: rem(1) solid $red-text-color;
        color: $red-text-color;
      }

      &:hover {
        button {
          background-color: $red-btn-hover;
        }
      }
    }
  }

}