.app {
  .container--nav {
    background-color: $nav-background;
    margin: 0px;
    color: white;
    display: flex;
    justify-content: center;
  }

  .nav__items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    align-items: center;
  }

  .nav__item {
    padding: 0 20px 0 20px;
    margin: 10px 5px 0 5px;
    align-items: center;

    &.item-wpl-logo {
      margin-top: 11px;
    }

    &.item-ka-logo {
      margin-top: 0;
      img {
        width: 280px;
        margin: 18px 0;
      }
    }

    img {
      width: 65px;
    }
  }
  .nav__item.ignis {
    img {
      height: 15px;
      width: auto;
    }
  }

}

.app[data-brand="ka"] {
  .container--nav {
    background-color: $red-text-color;
  }
}

@include desktop {
  .app {
    .nav__item {
      img {
        width: 100px;
      }
    }
    .nav__item.ignis {
      img {
        height: 20px;
        width: auto;
      }
    }
  }
}