.app {
  height: 100vh;
  overflow-x: hidden;
  .container--hero {
      height: auto;
  }

  .txt__years{
    font-weight: bold;
    font-size: 6vw;
    color: $orange-text-color;
  }
  .txt__title {
    font-weight: bold;
    font-size: 4vw;
    color: $orange-text-color;
    margin: 0 8vw;
  }
  .txt__first_line {
    font-weight: bold;
    font-size: 4vw;
    color: $orange-text-color;
    margin: 0 8vw;
  }
  .txt__second_line {
    font-weight: bold;
    font-size: 6vw;
  }
  .txt__third_line {
    margin-top: 2vh;
    font-size: 3.5vw;
  }

  .hero{
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.8);
    width: 90vw;
    margin: 5vw auto 0;
    padding: 3vh 0vw;
    border-radius: 20px;
  }
  .hero--finalData{
    .txt__title {
      font-weight: bold;
      font-size: 5vw;
      color: black;
    }
    .txt__first_line {
      font-weight: bold;
      font-size: 5vw;
      color: black;
    }
    .txt__second_line {
      font-weight: bold;
      font-size: 6vw;
      color: $orange-text-color;
    }
    .txt__third_line {
      margin-top: 20px;
      font-size: 4vw;
    }
    .txt__fouth_line {
      font-size: 5vw;
      color: $orange-text-color;
    }
  }

  .hero--infoMessage{
    background-color: rgba(255, 255, 255, 0.85);
    padding-left: 10px;
    padding-right: 10px;
  }

  .hero--and{
    background-color: white;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100px;
  }

  .no-marginTop-mobile{
    margin-top: 0;
  }

  .hero__and{
    font-size: 6vw;
    color: $orange-text-color;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 0;
  }
}

.app[data-brand="ka"] {

  .txt__years{
    color: $red-text-color;
  }
  .txt__title {
    color: $red-text-color;
  }
  .txt__first_line {
    color: $red-text-color;
  }

  .hero--finalData{
    .txt__second_line {
      color: $red-text-color;
    }
    .txt__fouth_line {
      color: $red-text-color;
    }
  }

  .hero__and{
    color: $red-text-color;
  }
}

@include desktop {
  .app {
    .container--hero {
      height: auto;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
    }
    .hero {
      width: 70vw;
      margin: 2.5vw auto 0;
      background-color: rgba(255, 255, 255, 0.55);
    }

    .no-marginTop-mobile{
      margin: 2.5vw 25px 0;
    }

    .hero--finalData{
      margin-left: 25px;
      margin-right: 25px;
    }

    .txt__title {
      font-size: 1.8vw;
      margin: 0 2vw;
    }
    .txt__first_line {
      font-size: 1.8vw;
      margin: 0 2vw;
    }
    .txt__second_line {
      font-weight: bold;
      font-size: 2.2vw;
    }
    .txt__third_line {
      margin-top: 1vh;
      font-size: 1.5vw;
    }

    .txt__years{
      font-size: 6vw;
      line-height: 5.5vw;
    }

    .hero--finalData{
      width: 35vw;

      .txt__title {
        font-weight: bold;
        font-size: 1.8vw;
        color: black;
      }
      .txt__first_line {
        font-weight: bold;
        font-size: 1.8vw;
        color: black;
      }
      .txt__second_line {
        font-weight: bold;
        font-size: 1.8vw;
        color: $orange-text-color;
      }
      .txt__third_line {
        font-weight: bold;
        font-size: 1.8vw;
      }
      .txt__fouth_line {
        font-weight: bold;
        font-size: 1.8vw;
        color: $orange-text-color;
      }
    }

    .hero--and{
      background-color: white;
      opacity: 1;
      position: absolute;
      width: auto;
      margin-top: 0;
      height: 100px;
      z-index: 9;
      left: initial;
      top: calc(50% - 60px)
    }

  }
}
@include desktop {
  .app[data-brand="ka"] {

    .hero--finalData{

      .txt__second_line {
        color: $red-text-color;
      }
      .txt__fouth_line {
        color: $red-text-color;
      }
    }

  }
}

@include wide {
  .app {
    .txt__title {
      font-size: 32px;
    }
    .txt__first_line {
      font-size: 32px;
    }
    .txt__second_line {
      font-size: 32px;
    }
    .txt__third_line {
      font-size: 28px;
    }

    .hero--finalData{
      .txt__title {
        font-size: 26px;
      }
      .txt__first_line {
        font-size: 26px;
      }
      .txt__second_line {
        font-size: 26px;
      }
      .txt__third_line {
        font-size: 26px;
      }
      .txt__fouth_line {
        font-size: 26px;
      }
    }
  }
}