//General config
$base-font-size: 16;

// Media query
$tablet: 768px;
$desktop: 1024px;
$xldesktop: 1920px;

// Generic color

// Color menu
$nav-background: #1d1d1b;
$menu-background: #f8f8f8;

// Colors

//Text colors
$orange-text-color: #ee7003;
$orange-btn-hover: #ef8d2b;
$input-text-color: #888888;

/////////////
// kitchenaid
/////////////

//Text colors
$red-text-color: #C41230;
$red-btn-hover: #C41230;