.app {
  .container--form {
    height: 60vw;
    display: flex;
    padding-bottom: 10vw;
    flex-direction: column;
    align-items: center;
    padding-top: 2vw;
  }

  .form__items {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    align-items: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
    border-radius: 20px;
    //background-image: url("../../images/lavatrici-sfondo.jpg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 65vw;
  }
  
  .form__item {
    &.first {
      width: 90%;
      text-align: center;
      font-size: 4vw;
      font-weight: bold;
      line-height: 6vw;
      margin-top: 10px;
      & button {
        color: $orange-text-color;
        background-color: $nav-background;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        padding: 15px;
        border: none;
        text-transform: uppercase;
        margin-top: 20px;
      }
      
    }
    & .error{
      color: $orange-text-color;
      margin-top: 20px;
    }
    &.loader{
      border: 8px solid #f3f3f3;
      border-top: 8px solid $orange-text-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
      margin: 0 auto;
    }

    &.second {
      width: 100%;
      text-align: center;
      font-size: 24vw;
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &.third {
      display: flex;
      width: 80%;
      font-size: 4vw;
      flex-wrap: wrap;
      /*background-color: #ee7003;*/
      margin-bottom: 3vw;
      justify-content: center;
      align-items: center;
      align-content: center;
      line-height: 6vw;
    }

    &.popup-cta {
      text-align: center;
      color: $orange-text-color;
      font-weight: bold;
      font-size: 4vw;
    }

    &.additional-information {
      margin-top: 2vw;
      font-size: 3.5vw;
      & .additional-information__title {
        color: $orange-text-color;
        text-transform: uppercase;
      }
    }
  }

  .footer {
    position: fixed;
    padding: 10px 0px;
    bottom: 0px;
    right: 0px;
    background-color: $nav-background;
    margin: 0px;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 2.6vw;
    a {
      color: white;
    }
  }
}

.app[data-brand="ka"] {
  
  .form__item {
    &.first {
      & button {
        color: $red-text-color;
      }
      
    }
    & .error{
      color: $red-text-color;
    }
    &.loader{
      border-top: 8px solid $red-text-color;
    }

    &.popup-cta {
      color: $red-text-color;
    }

    &.additional-information {
      & .additional-information__title {
        color: $red-text-color;
      }
    }
  }

  .footer {
    background-color: $red-text-color;
  }

}

@include desktop {
  .app {
    .container--form {
      height: 18vw;
      display: flex;
      padding-bottom: 10vw;
    }

    .form__items {
      width: 60vw;
      background-size: 25vw;
    }

    .form__item {
      &.first {
        width: 90%;
        text-align: center;
        font-size: 2vw;
        line-height: 2vw;
      }

      &.second {
        width: 90%;
        font-size: 2vw;
        flex-wrap: nowrap;
      }

      &.third {
        display: flex;
        width: 90%;
        font-size: 1.3vw;
        flex-wrap: nowrap;
        /*background-color: #ee7003;*/
        margin-bottom: 1.5vw;
        justify-content: flex-start;
        align-items: center;
        line-height: 2.5vw;
      }

      &.no-model {
        font-size: 1.25vw;
      }

      &.popup-cta {
        font-size: 2vw;
      }

      &.additional-information {
        font-size: 1.5vw;
      }


      .container-brand-list {
        margin-left: 10px;

        .brand__items {
          color: white;

          .brand__item {

            &.first {
              display: flex;
              justify-content: center;
              padding: 1px 10px 1px 10px;
              border-radius: 10px;
              width: 14.7vw;
            }

            .brand {
              font-size: 1.0vw;
            }
          }
          .brand__list {
            width: 16vw;

            ul {
              li {
                font-size: 1.0vw;
                list-style: none;
                padding-top: rem(0.1);
                padding-bottom: rem(0.1);
              }
            }
          }
        }
      }

      .input-field {
        width: 100%;
        padding-top: 2vw;
        padding-bottom: 2vw;
        margin-right: 1vw;
        input {
          width: 100%;
          height: 3vw;
          font-size: 1.3vw;
        }
      }

      &.autocomplete {
        left: 0;
        font-size: 1.3vw;
        height: auto;
        display: flex;
        justify-content: left;
        padding-left: 1.5vw;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        width: 77%;
        top: 6.5vw;
        min-height: 8.5rem;
      }

      &.checkbox-cta {
        margin-right: 0.87rem;
        margin-left: auto;
        margin-bottom: -0.2rem;
        border: 1px solid $orange-text-color;
        box-sizing: border-box;
        padding: 10px;

        &:checked {
          background-image: url("../../images/tick.png");
          background-size: 90%;
          background-position: center;
          background-repeat: no-repeat;
          &:after {
            visibility: visible;
          }
        }
      }
    }

    .confirm-btn {
      button {
        font-size: 0.8vw;
        height: 3.7vw;
        width: 10vw;
      }
    }
    .footer {
      font-size: 0.8vw;
    }
  }
}

@include desktop {
  .app[data-brand="ka"] {

    .form__item {

      &.checkbox-cta {
        border: 1px solid $orange-text-color;
      }
    }

  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}